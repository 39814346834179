import store from '@/stores'
const routes = [
  {
    path: '/company/:company',
    component: () => import('@/pages/company/_id.vue'),
    name: 'Company',
    meta: {
      title: 'Description',
      auth: true,
    },
    beforeEnter(to, from, next) {
      if (!from.name && from.path === '/') {
        /**
         * if the user navigates to the page from the browser address bar
         * (e.g. refreshing the entire page) the user's watch list needs to be fetched
         * in order to make the header handling the star icon state properly
         */
        store.dispatch('User/getWatchList')
        next()
      } else {
        next()
      }
    },
    children: [
      {
        alias: '',
        path: 'description',
        component: () => import('@/pages/company/Description.vue'),
        meta: {
          title: 'Description',
          auth: true,
        },
      },
      {
        alias: '',
        path: 'documents',
        component: () => import('@/pages/company/DocumentsPage.vue'),
        meta: {
          title: 'Documents',
          auth: true,
        },
      },
      {
        path: 'financials',
        name: 'Company Model',
        component: () => import('@/pages/company/_model.vue'),
        meta: {
          title: 'Financials',
          auth: true,
        },
        children: [
          {
            name: 'Custom Company Model',
            path: ':model/:version?',
            component: () => import('@/pages/company/_model.vue'),
            meta: {
              title: 'Financials',
              auth: true,
            },
          },
        ],
      },
      {
        path: 'forecasting',
        name: 'Company Forecasting',
        meta: {
          title: 'Forecasting Home',
          auth: true,
        },
        redirect: { name: 'Company Forecasting Home' },
        component: () => import('@/pages/PassThrough.vue'),
        children: [
          {
            name: 'Company Forecasting Home',
            path: 'home',
            component: () => import('@/components/company/forecasting/ForecastingHome.vue'),
            meta: {
              title: 'Forecasting Home',
              auth: true,
            },
          },
          {
            name: 'Company Forecasting Model',
            path: ':model',
            component: () => import('@/pages/company/_model.vue'),
            props: true,
            meta: {
              title: 'Forecasting',
              auth: true,
            },
          },
        ],
      },
      {
        path: 'instruments',
        meta: {
          title: 'Instruments',
          auth: true,
        },
        component: () => import('@/pages/company/InstrumentsPage.vue'),
      },
    ],
  },
]

export default routes
